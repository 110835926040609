<script setup>
import { computed, useSlots } from "vue";
import getId from "@/util/getId";
import FhOptionGroupItems from "@/components/FhOptionGroupItems.vue";

const props = defineProps({
  /**
   * Model of the component; Either use this property (along with a listener for 'update:model-value' event) OR use v-model directive
   */
  modelValue: {
    type: undefined,
    required: true
  },
  type: {
    type: String,
    default: "radio",
    validator: (val) => ["radio", "checkbox"].includes(val)
  },
  label: {
    type: String,
    default: undefined
  },
  options: {
    type: Array,
    required: true
  },
  /**
   * Controls the size of the label and options; corresponds to standard fluid body text sizes
   * @values base, lg, xl
   */
  size: {
    type: String,
    default: "base",
    validator: (val) => ["base", "lg", "xl"].includes(val)
  },
  required: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  errorMessage: {
    type: String,
    default: undefined
  },
  /**
   * The Vuelidate validation object for this field. Use this to automatically set the error state and errorMessage.
   * The `errorMessage` prop will override any error messages from this prop if both are set.
   */
  vuelidate: {
    type: Object,
    default: () => undefined
  }
});

const emit = defineEmits(["update:modelValue"]);

const value = computed({
  get: () => props.modelValue,
  set: (value) => {
    emit("update:modelValue", value);
  }
});

const slots = useSlots();

const errorMessageId = getId();
const hasVuelidateError = computed(() => !!props.vuelidate?.$error);
const hasError = computed(() => props.error || hasVuelidateError.value);
const hasErrorMessage = computed(() => (props.error && (props.errorMessage || slots.errorMessage)) || hasVuelidateError.value);
</script>

<template>
  <fieldset
    class="md:max-h-72 md:overflow-y-auto"
    :aria-required="props.required"
    :aria-invalid="hasError"
    :aria-errormessage="hasErrorMessage ? errorMessageId : false"
  >
    <legend v-if="props.label" class="mb-f2 flex flex-row flex-wrap items-baseline gap-f1">
      <span>{{ label }}</span>
      <span v-if="props.required" class="text-body-xs text-neutral-50">(Required)</span>
    </legend>
    <div class="flex flex-col gap-f2 pr-2">
      <FhOptionGroupItems
        v-for="option in props.options"
        :key="option.value"
        v-model="value"
        :option="option"
        :type="props.type"
        :size="props.size"
        :vuelidate="props.vuelidate"
      >
        <template #label>
          <div class="mr-f3 whitespace-nowrap">{{ option.label }}</div>
        </template>
        <template #count>
          <div class="w-6 text-right text-xs" :class="{ 'text-neutral-50': !option.selected }">{{ option.count }}</div>
        </template>
      </FhOptionGroupItems>
    </div>
    <div v-if="hasErrorMessage" :id="errorMessageId" class="text-body-sm mt-f2 max-w-prose text-brand-red">
      <slot name="errorMessage">
        <template v-if="props.errorMessage">{{ props.errorMessage }}</template>
        <template v-else-if="hasVuelidateError">
          <div v-for="errObj of props.vuelidate.$errors" :key="errObj.$uid">{{ errObj.$message }}</div>
        </template>
      </slot>
    </div>
  </fieldset>
</template>
