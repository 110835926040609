<script setup>
import { computed, ref } from "vue";
import FhIcon from "@/components/FhIcon.vue";
import FhRadio from "@/components/FhRadio.vue";
import FhCheckbox from "@/components/FhCheckbox.vue";

const props = defineProps({
  /**
   * Model of the component; Either use this property (along with a listener for 'update:model-value' event) OR use v-model directive
   */
  modelValue: {
    type: undefined,
    required: true
  },
  type: {
    type: String,
    default: "radio",
    validator: (val) => ["radio", "checkbox"].includes(val)
  },
  option: {
    type: Object,
    required: true
  },
  /**
   * Controls the size of the label and options; corresponds to standard fluid body text sizes
   * @values base, lg, xl
   */
  size: {
    type: String,
    default: "base",
    validator: (val) => ["base", "lg", "xl"].includes(val)
  },
  /**
   * The Vuelidate validation object for this field. Use this to automatically set the error state and errorMessage.
   * The `errorMessage` prop will override any error messages from this prop if both are set.
   */
  vuelidate: {
    type: Object,
    default: () => undefined
  }
});

const emit = defineEmits(["update:modelValue"]);

const parentOptionRef = ref(null);

const parentValue = computed(() => props.option.value);
const nestedOptions = computed(() => props.option?.options || []);
const nestedOptionsValue = computed(() => {
  return nestedOptions.value.map((nestedOption) => nestedOption.value);
});

const value = computed({
  get: () => props.modelValue,
  set: (selectedValue) => {
    // If select has children let change triggers run
    // else proceed normally with update
    if (nestedOptions.value.length === 0) {
      emit("update:modelValue", selectedValue);
    }
  }
});

const itemComponent = computed(() => {
  switch (props.type) {
    case "radio":
      return FhRadio;
    case "checkbox":
      return FhCheckbox;
    default:
      throw new Error(`Unsupported type: ${props.type}`);
  }
});

const isInditerm = computed(() => {
  const hasParent = value.value.includes(parentValue.value);
  const hasChildValue = value.value.find((val) => nestedOptionsValue.value.includes(val));
  if (hasChildValue && !hasParent) return true;
  return false;
});

const showNestedOptions = ref(value.value.find((val) => nestedOptionsValue.value.includes(val)) || value.value.includes(parentValue.value));

const optionChangeEvent = (event) => {
  if (props.vuelidate) props.vuelidate.$touch();

  if (nestedOptionsValue.value.length === 0) return; // bail if there are no children

  if (isInditerm.value) {
    const childrenRemoved = Object.values(value.value).filter((val) => !nestedOptionsValue.value.includes(val));
    emit("update:modelValue", childrenRemoved);
    return;
  }

  if (event.target.checked) {
    emit("update:modelValue", [...value.value, ...nestedOptionsValue.value, event.target.value]);
    showNestedOptions.value = true;
  } else {
    const childrenRemoved = Object.values(value.value).filter((val) => !nestedOptionsValue.value.includes(val) && val != parentValue.value);
    emit("update:modelValue", childrenRemoved);
  }
};

const triggerNestedOptions = () => {
  showNestedOptions.value = !showNestedOptions.value;
};

const triggerInditerm = (event) => {
  const selectedValue = event.target.value;
  if (event.target.checked) {
    // Add child value
    emit("update:modelValue", [...value.value, selectedValue]);
  } else {
    // remove child value
    const updatedModel = value.value.filter((val) => val != selectedValue && val != parentValue.value);
    emit("update:modelValue", updatedModel);
  }
};
</script>

<template>
  <div class="flex items-center justify-between">
    <component
      :is="itemComponent"
      ref="parentOptionRef"
      v-model="value"
      :value="props.option.value"
      :disabled="props.option.disabled"
      :indeterminate="isInditerm"
      :size="props.size"
      class="grow"
      data-ga="filter_checkbox"
      :data-ga-value="props.option.label"
      @change="optionChangeEvent"
    >
      <!-- @slot Can be used to customize the option label content. Receives the `option` object as a parameter. -->
      <slot name="label" v-bind="props.option">
        {{ props.option.label }}
      </slot>
    </component>
    <div class="flex w-full items-center justify-end self-center">
      <span v-if="nestedOptions?.length > 0" class="cursor-pointer pl-2 text-2xl leading-none" @click="triggerNestedOptions">
        <FhIcon name="DownChevron" :class="{ 'rotate-180': showNestedOptions }" aria-hidden="true" />
      </span>
      <slot name="count" v-bind="props.option">
        {{ props.option.count }}
      </slot>
    </div>
  </div>
  <div v-if="nestedOptions?.length > 0 && showNestedOptions" class="flex flex-col gap-f2 pl-6">
    <component
      :is="itemComponent"
      v-for="nestedOption in nestedOptions"
      :key="nestedOption.value"
      v-model="value"
      :value="nestedOption.value"
      :disabled="nestedOption.disabled"
      :size="props.size"
      class="grow"
      :data-ga-value="nestedOption.label"
      @change="triggerInditerm"
    >
      <div class="flex w-full items-baseline justify-between">
        <div class="mr-f3 whitespace-nowrap">{{ nestedOption.label }}</div>
        <div class="text-xs" :class="{ 'text-neutral-50': !nestedOption.selected }">{{ nestedOption.count }}</div>
      </div>
    </component>
  </div>
</template>
