<script setup>
import { ref, computed, provide, reactive, onMounted, nextTick, watch } from "vue";
import FhProductListingPresenter_InfiniteScroll from "@/components/FhProductListingPresenter.vue";
import { useInfiniteProductQuery } from "@/api/productServiceApi";
import { event_viewListItem } from "@/util/googleAnalytics";
import { useProductFiltersStore } from "@/stores/ProductFiltersStore";
import FhButton from "@/components/FhButton.vue";
import FhLoader from "@/components/FhLoader.vue";
import { useViewportSizes } from "@/composables";

const props = defineProps({
  baseQuery: {
    type: Array,
    default: () => []
  },
  categoryId: {
    type: Number,
    default: 0
  },
  filters: {
    type: Array,
    default: () => []
  },
  sortOptions: {
    type: Array,
    default: () => []
  },
  searchText: {
    type: String,
    default: undefined
  },
  categoryList: {
    type: Array,
    default: () => []
  },
  isAdmin: {
    type: Boolean,
    default: false
  },
  priceMarkup: {
    type: Object,
    default: () => {}
  },
  isCADPrice: {
    type: Boolean,
    default: false
  },
  isCustomerGuest: {
    type: Boolean,
    default: false
  },
  productListingClientComponentParams: {
    type: Object,
    default: () => {}
  }
});

const productFilterStore = useProductFiltersStore();
const { searchParams, setURLParam, updateURL, currentPage, currentSortOrder } = productFilterStore.urlState;

const { isSm, isMd } = useViewportSizes();

const lastScrollPosition = ref(0);
const filterKeysToFacet = props.filters.map((filter) => filter.key);
const isFirstLoad = ref(true);
const resultSetContainer = ref(null);
const isViewMore = ref(false);

const headerElement = ref(null);
const productFilterElement = ref(null);

const infiniteScrollDefaults = reactive({
  initialPageSize: 18,
  normalPageSize: 18,
  displayThreshold: computed(() => (isSm.value || isMd.value ? 102 : 105))
});

const sortOptions = computed(() => {
  if (!props.sortOptions) return [];
  return props.sortOptions;
});
const sortOrder = ref(currentSortOrder || sortOptions.value[0]?.key);
const scrollComponent = ref(null);

const merchandisingSkus = computed(() => {
  if (!props.productListingClientComponentParams) return [];
  return props.productListingClientComponentParams.merchandisingSkus;
});

const baseQuery = computed(() => {
  if (!props.baseQuery) return [];
  return props.baseQuery;
});

const baseFilterQuery = buildFiltersQuery(baseQuery.value, searchParams);

const searchBody = ref({
  pageSize: infiniteScrollDefaults.initialPageSize,
  pageIndex: currentPage,
  sortOrder: sortOrder,
  offset: 0,
  filters: baseFilterQuery,
  filterKeysToFacet: filterKeysToFacet,
  searchText: props.searchText,
  condensed: true,
  merchandisingSkus: merchandisingSkus
});

const activeFilters = ref(searchParams);

function handleActiveFiltersChange(filters) {
  const filterQueryTransform = Object.entries(filters).map(([key, value]) => {
    return {
      key: key,
      values: value
    };
  });

  searchBody.value.pageIndex = 0;
  searchBody.value.offset = 0;
  searchBody.value.pageSize = infiniteScrollDefaults.initialPageSize;
  activeFilters.value = filterQueryTransform;
  isViewMore.value = false;

  searchBody.value.filters = buildFiltersQuery(baseQuery.value, filterQueryTransform);
}

function buildFiltersQuery(baseFilterQuery, updatedFilterQuery) {
  const filteredBaseQuery = baseFilterQuery.filter((queryObj) => {
    return !updatedFilterQuery.find((filterQuery) => filterQuery.key === queryObj.key);
  });

  return [...filteredBaseQuery, ...updatedFilterQuery];
}

const {
  data: productListingsQueryData,
  fetchNextPage,
  hasNextPage,
  isFetching,
  isFetchingNextPage,
  isError
} = useInfiniteProductQuery(searchBody.value, activeFilters, sortOrder);

const productListings = reactive({
  total: computed(() => productListingsQueryData.value?.pages[0].total || 0),
  facets: computed(() => productListingsQueryData.value?.pages[0].facets || []),
  products: computed(() => {
    return productListingsQueryData.value?.pages.map((listings) => listings.products).flat();
  })
});

const thresholdMeet = computed(() => {
  if (productListings.products) {
    return (
      productListings.products.length >= infiniteScrollDefaults.displayThreshold ||
      productListings.total <= infiniteScrollDefaults.initialPageSize ||
      (!hasNextPage.value && productListings.products.length <= infiniteScrollDefaults.displayThreshold)
    );
  }

  return false;
});

const percantageComplete = computed(() => {
  if (productListings.products) {
    return (productListings.products.length / productListings.total) * 100;
  }
  return 0;
});

provide("fetching_products", isFetching);
provide("product_category_list", props.categoryList);

//TODO: Will leave in for now until we get final word on tracking for this
watch(productListings, () => {
  if (history.state && isFirstLoad.value) {
    nextTick(() => {
      window.scrollTo(0, history.state?.scroll_position);
      history.replaceState(null, "");
    });
  }

  if (!isFirstLoad.value && !isViewMore.value) {
    const resultSet = resultSetContainer.value.productsContainer;
    const chipsBar = document.querySelector("[data-product-chips]");
    const filterBar = document.querySelector(".c-productFilterBar");
    const offsetTop = chipsBar ? chipsBar.offsetTop : resultSet.offsetTop;
    nextTick(() => {
      window.scrollTo({
        top: offsetTop - filterBar.offsetHeight,
        left: 0,
        behavior: "smooth"
      });
    });
  } else {
    event_viewListItem(productListings.products, props.categoryList);
  }

  isFirstLoad.value = false;
});

const handleSort = (value) => {
  setURLParam("sort", value);
  updateURL();
  searchBody.value.pageIndex = 0;
  searchBody.value.offset = 0;
  searchBody.value.pageSize = infiniteScrollDefaults.initialPageSize;
  sortOrder.value = value;
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);
  window.addEventListener("beforeunload", unmounted);
  headerElement.value = document.querySelector("header");
  productFilterElement.value = document.querySelector(".c-productFilterBar");
  if (headerElement.value) {
    nextTick(() => document.documentElement.style.setProperty("--header-height", `${headerElement.value.offsetHeight}px`));
  }
});

const unmounted = () => {
  history.replaceState(
    {
      scroll_position: document.documentElement.scrollTop,
      products_length: productListings.products.length
    },
    ""
  );
};

const handleScroll = () => {
  const currentScrollPosition = document.documentElement.scrollTop;

  const scrollElement = scrollComponent.value;
  if (scrollElement.getBoundingClientRect().bottom - 300 < window.innerHeight) {
    fetchMoreProduct();
  }

  if (currentScrollPosition <= headerElement.value.offsetHeight) {
    if (headerElement.value.classList.contains("show-header")) {
      headerElement.value.classList.remove("show-header");
      headerElement.value.classList.remove("animate-header-slide");
      productFilterElement.value.classList.remove("animate-main-content-slide");
    }
    // bail so we don't inadvertantly show the header
    return;
  }

  if (currentScrollPosition < lastScrollPosition.value) {
    if (!headerElement.value.classList.contains("show-header")) {
      headerElement.value.classList.add("show-header");
      headerElement.value.classList.add("animate-header-slide");
      productFilterElement.value.classList.add("animate-main-content-slide");
    } else if (headerElement.value.classList.contains("show-header") && headerElement.value.classList.contains("animate-header-slide-leave")) {
      headerElement.value.classList.remove("animate-header-slide-leave");
      productFilterElement.value.classList.remove("animate-main-content-slide-leave");
      headerElement.value.classList.add("animate-header-slide");
      productFilterElement.value.classList.add("animate-main-content-slide");
    }
  } else {
    if (headerElement.value.classList.contains("show-header")) {
      headerElement.value.classList.remove("animate-header-slide");
      productFilterElement.value.classList.remove("animate-main-content-slide");
      headerElement.value.classList.add("animate-header-slide-leave");
      productFilterElement.value.classList.add("animate-main-content-slide-leave");
    }
  }

  lastScrollPosition.value = currentScrollPosition;
};

const fetchMoreProduct = () => {
  if (!isFetchingNextPage.value && hasNextPage.value && !thresholdMeet.value) {
    searchBody.value.pageSize = infiniteScrollDefaults.normalPageSize;
    isViewMore.value = true;
    fetchNextPage();
  }
};

const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};
</script>

<template>
  <div>
    <div ref="scrollComponent">
      <FhProductListingPresenter_InfiniteScroll
        ref="resultSetContainer"
        :is-loading="isFetching && !isFetchingNextPage"
        :products="productListings?.products"
        :total-products="productListings?.total"
        :filters="props.filters"
        :sort-options="props.sortOptions"
        :sort-order="sortOrder"
        :active-filters="activeFilters"
        :facet-counts="productListings?.facets"
        :has-error="isError"
        :is-admin="props.isAdmin"
        :price-markup="props.priceMarkup"
        :is-c-a-d-price="props.isCADPrice"
        :is-customer-guest="props.isCustomerGuest"
        :merchandising-skus="merchandisingSkus"
        @update:active-filters="handleActiveFiltersChange"
        @update:sort-value="handleSort"
      />
    </div>
    <div class="py-20">
      <span v-if="isFetchingNextPage"><FhLoader class="h-9 w-9" /></span>
      <div v-else-if="thresholdMeet" class="flex flex-col justify-center items-center">
        <div class="mb-8 w-56">
          <span class="text-base font-medium text-neutral-50 block w-full text-center mb-2"
            >Showing {{ productListings.products?.length }} of {{ productListings.total }}</span
          >
          <div class="mb-5 h-2 rounded-full bg-neutral-30">
            <div class="h-2 rounded-full bg-black" :style="{ width: `${percantageComplete}%` }"></div>
          </div>
        </div>
        <div class="flex items-center justify-center gap-3">
          <FhButton class="py-4 px-8" data-fh-ga="scroll-to-top" @click="scrollToTop">Back To Top</FhButton>
          <FhButton
            v-if="hasNextPage && !isFetchingNextPage"
            color="primary"
            class="py-4 px-8"
            data-fh-ga="load-more"
            :disabled="isFetchingNextPage"
            @click="() => fetchNextPage()"
            >View More</FhButton
          >
        </div>
      </div>
    </div>
  </div>
</template>
