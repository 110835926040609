<script setup>
import { reactive, ref, watch, toRef } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { helpers, required, maxLength } from "@vuelidate/validators";
import FhButton from "@/components/FhButton.vue";
import FhCheckoutHoldDateSelect from "@/components/FhCheckoutHoldDateSelect.vue";
import FhInput from "@/components/FhInput.vue";
import FhInputPhone from "@/components/FhInputPhone.vue";
import { getHoldDateLimits } from "@/util/dateFunctions";

const props = defineProps({
  pickUpContactName: {
    type: String,
    default: null
  },
  pickUpContactPhone: {
    type: String,
    default: null
  },
  holdDate: {
    type: String,
    default: null
  },
  isSaving: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(["complete"]);
const isNextStepAvailable = ref(true);
const isOrderShippingLater = ref(!!props.holdDate);

const data = reactive({
  pickUpContactName: props.pickUpContactName,
  pickUpContactPhone: props.pickUpContactPhone,
  holdDate: props.holdDate
});

// Update `data` values in response to corresponding props changing
Object.keys(data).forEach((key) => watch(toRef(props, key), (val) => (data[key] = val)));

const { minDate, maxDate } = getHoldDateLimits();

const isDateValid = (value) => {
  isNextStepAvailable.value =
    !isOrderShippingLater.value || (isOrderShippingLater.value && data.holdDate && (!helpers.req(value) || (value <= maxDate && value >= minDate)));
  return isNextStepAvailable.value;
};

const vuelidate = useVuelidate(
  {
    pickUpContactName: {
      required: helpers.withMessage("Please enter a pick-up contact name.", required),
      maxLength: helpers.withMessage("Please enter no more than 50 characters.", maxLength(50))
    },
    pickUpContactPhone: {
      required: helpers.withMessage("Please enter a pick-up contact phone number.", required),
      validPhone: helpers.withMessage("Please enter a valid pick-up contact phone number.", helpers.regex(/^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/))
    },
    holdDate: {
      isDateValid: helpers.withMessage("Please choose a date within 90 days from today.", isDateValid)
    }
  },
  data
);

function complete() {
  if (vuelidate.value.$validate()) {
    emit("complete", { ...data });
  }
}
</script>

<template>
  <div class="mt-f5 flex flex-col gap-f8">
    <fieldset>
      <legend class="mb-f4 text-f-base-xl">Enter your pick up details</legend>
      <div class="grid gap-f3 md:grid-cols-2">
        <FhInput v-model="data.pickUpContactName" label="Contact Name" required :vuelidate="vuelidate.pickUpContactName" />
        <FhInputPhone v-model="data.pickUpContactPhone" label="Phone Number" required :vuelidate="vuelidate.pickUpContactPhone" />
      </div>
    </fieldset>
    <FhCheckoutHoldDateSelect
      v-model="data.holdDate"
      v-model:selected="isOrderShippingLater"
      :min-date="minDate"
      :max-date="maxDate"
      :vuelidate="vuelidate"
      :vuelidate-field="vuelidate.holdDate"
    />
    <FhButton color="primary" class="w-full" :disabled="vuelidate.$invalid || props.isSaving || !isNextStepAvailable" @click="complete"
      >Continue to Payment</FhButton
    >
  </div>
</template>
