<script setup>
import { computed } from "vue";
import FhCheckbox from "./FhCheckbox.vue";
import FhInput from "./FhInput.vue";

const props = defineProps({
  modelValue: {
    type: String,
    default: null
  },
  selected: {
    type: Boolean,
    default: false
  },
  minDate: {
    type: String,
    default: null
  },
  maxDate: {
    type: String,
    default: null
  },
  vuelidateField: {
    type: Object,
    default: () => undefined
  },
  vuelidate: {
    type: Object,
    default: () => undefined
  }
});

const emit = defineEmits(["update:modelValue", "update:selected"]);

const toggleCheckbox = (isSelected) => {
  // Avoid posting a hold date when checkbox is unchecked
  emit("update:selected", isSelected);
  if (!isSelected) {
    emit("update:modelValue", null);
    props.vuelidate.$reset();
  }
};

const value = computed({
  get: () => {
    if (!props.modelValue || isNaN(new Date(props.modelValue))) return null;
    return new Date(props.modelValue).toISOString().substring(0, 10);
  },
  set: (val) => emit("update:modelValue", val)
});
</script>

<template>
  <fieldset>
    <legend class="mb-f3 text-f-base-xl">Don't Want Your Order Shipped Yet? <span class="text-neutral-50">(Optional)</span></legend>
    <FhCheckbox
      :model-value="props.selected"
      label="Select the date you&rsquo;d like us to ship your order"
      note="We will begin processing your order 3-5 days prior to the date you specify and ship product as it is available. Please note: selecting a shipment date does not hold inventory or guarantee product will be in stock on the specified date."
      @update:model-value="toggleCheckbox"
    />
    <div v-if="props.selected" class="mt-f3 ml-[1em] w-56 pl-f2">
      <FhInput v-model="value" type="date" label="Select your date" :min="props.minDate" :max="props.maxDate" :vuelidate="vuelidateField" />
    </div>
  </fieldset>
</template>

<style scoped></style>
