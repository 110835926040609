import axios from "axios";
import forge from "node-forge";

class PaytraceEncryption {
  constructor() {
    this.pub = null;
    this.setKeyAjax("/api/paytrace");
  }

  setKey(public_key_pem) {
    this.pub = forge.pki.publicKeyFromPem(public_key_pem);
  }

  setKeyAjax(key_url) {
    axios
      .get(key_url)
      .then((public_key_pem) => {
        this.setKey(public_key_pem.data);
      })
      .catch(() => {
        // throw new Error(`JAX Key request failed: ${error.message}`);
      });
  }

  encryptValue(data) {
    if (!this.pub) throw new Error("No public key was provided (PayTrace E2EE).");
    return forge.util.encode64(this.pub.encrypt(data));
  }
}

export default PaytraceEncryption;
