<script setup>
import { useUserStore } from "@/stores/UserStore";
import { useFeaturesStore } from "@/stores/FeaturesStore";
import { reactive } from "vue";
const props = defineProps({
  globalState: {
    type: Object,
    default: () => {}
  }
});

const userStore = useUserStore();
const featuresStore = useFeaturesStore();
const stateObj = reactive({
  user: props.globalState?.userState || null,
  features: props.globalState.features
});

if (stateObj.user) {
  userStore.init(stateObj.user);
}

featuresStore.init(stateObj.features);
</script>
<template>
  <slot></slot>
</template>
